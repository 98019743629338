<template>
  <div>
    <div class="row justify-content-center align-items-md-center">
      <div class="col-md-12">
        <div class="card full-height bg-ligth">
          <div class="card-header">
            <h3 class="mb-0">Detalhes do pedido de recebimento</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <form action="">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-md-12">Usuario :*</label>
                      <input
                          class="form-control"
                          v-model="form.pedido.usuario"
                          disabled
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-md-12">Descrição:*</label>
                      <input
                          disabled
                          type="text"
                          class="form-control"
                          v-model="form.pedido.descricao"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="col-md-12">Emissao:*</label>
                      <input
                          disabled
                          type="date"
                          class="form-control"
                          v-model="form.pedido.emissao"
                          placeholder="Digite o nome..."
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="col-md-12">Vencimento:*</label>
                      <input
                          disabled
                          type="date"
                          class="form-control"
                          v-model="form.pedido.data_validade"
                          placeholder="Digite o nome..."
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-md-12">Forma de Pagamento :*</label>
                      <select
                          class="form-control"
                          v-model="form.pedido.finan_forma_pagamento_id"
                          disabled
                      >
                        <option
                            v-for="(lista, index) in lista_finan_modo_pagamentos"
                            :key="index"
                            :value="lista.id"
                        >
                          {{ lista.nome }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-md-12">Cambio :*</label>
                      <select
                          disabled
                          class="form-control"
                          v-model="form.pedido.finan_cambio_id"
                          name=""
                      >
                        <option
                            v-for="(lista, index) in lista_finan_cambios"
                            :key="index"
                            :value="lista.id"
                        >
                          {{ lista.nome }}
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 border-left d-flex flex-column justify-content-around">
                <div>
                  <h4>Produtos</h4>
                  <div class="table-responsive mt-5">
                    <b-table
                        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                        :items="lista_itens_produto"
                        show-empty
                        empty-text="Nenhum registro encontrado!"
                        :fields="['nome_produto','quantidade', 'valor_unitario', 'acao']"
                    >
                      <template #head(acao)=""><span></span></template>

                      <template #cell(valor_unitario)="{item}">
                        {{ item.valor_unitario | formatMoney }}
                      </template>

                      <template #custom-foot="{items}">
                        <div class="ml-2" v-if="items.length">
                          <strong>Total:</strong> {{ valorTotal | formatMoney }}
                          <br>
                          <strong>Desconto:</strong> {{ totalDesconto | formatMoney }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>

                <hr class="w-100">

                <div class="mt-10">
                  <h4>Parcelas</h4>
                  <div class="table-responsive mt-5">
                    <b-table
                        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                        :items="lista_finan_contas_receber"
                        show-empty
                        empty-text="Nenhum registro encontrado!"
                        :fields="['id','valor', 'vencimento', 'banco', 'acao']"
                    >
                      <template #head(acao)=""><span></span></template>

                      <template #cell(valor)="{item}">
                        {{ item.valor | formatMoney }}
                      </template>

                      <template #cell(vencimento)="{item}">
                        {{ item.vencimento | formatDBDateToLocale }}
                      </template>

                      <template #cell(banco)="{item}">
                        <span v-if="item.banco && item.conta_id">{{ item.conta_id + ' - ' + item.banco }}</span>
                      </template>

                      <template #cell(acao)="{item}">
                        <div class="text-right">
                          <button
                              v-if="item.baixa === null"
                              @click="baixar(item)"
                              class="btn btn-icon btn-light btn-sm mx-1 text-warning"
                              v-b-tooltip.hover title="Dar Baixa"
                          >
                            <i class="far fa-list-alt text-warning"></i>
                          </button>
                          <span v-b-tooltip="`Baixada ${item.baixa}`">
                      <button
                          v-if="item.baixa !== null"
                          class="btn btn-icon btn-light btn-sm mx-1  disabled"
                      >
                        <i class="far fa-list-alt text-success"></i>
                      </button>
                    </span>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {fireAlert} from "@/core/mixins/alertMessageMixin";
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import moneyFormat from "@/core/mixins/moneyFormat";
import dateFormat from "@/core/mixins/dateFormat";

export default {
  mixins: [fireAlert, moneyFormat, dateFormat],
  data() {
    return {
      form: {
        pedido: {
          usuario_id: "",
          nome: "",
          descricao: "",
          status: 1,
          emissao: "",
          data_validade: "",
          valor_total: "",
          finan_forma_pagamento_id: null,
          finan_cambio_id: null,
        },
        itens: [],
      },
      itens: {
        nome_produto: null,
        produto_id: null,
        quantidade: null,
        valor_desconto: "",
      },
      verif: false,
    };
  },
  mounted() {
    this.$store.dispatch("MudarPreloader", true);
    this.listar_requerimentos()
        .finally(() => {
          this.$store.dispatch("MudarPreloader", false);
          this.preenxerCampos()
        });
    this.$store.dispatch(SET_BREADCRUMB, [{title: `Detalhes do Pedido de Recebimento`}]);
  },
  computed: {
    totalDesconto() {
      return this.lista_itens_produto.reduce((curr, item) => {
        return curr + item.valor_desconto
      }, 0)
    },
    valorTotal() {
      return this.lista_itens_produto.reduce((curr, item) => {
        return curr + (item.valor_unitario * item.quantidade)
      }, 0)
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_finan_contas_receber() {
      return this.$store.state.financeiro.lista_finan_contas_receber;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos || {id: this.$route.params.pedido_id}
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
    lista_finan_cambios() {
      return this.$store.state.financeiro.lista_finan_cambios;
    },
    lista_finan_modo_pagamentos() {
      return this.$store.state.financeiro.lista_finan_modo_pagamentos;
    },
    lista_finan_caixas() {
      return this.$store.state.configEmpresa.lista_dados_guardados;
    },
    lista_produtos() {
      return this.$store.state.financeiro.lista_produtos;
    },
    lista_itens_produto() {
      return this.$store.state.financeiro.lista_itens_produto;
    },
    lista_usuarios() {
      return this.$store.state.configFuncionario.lista_usuarios;
    },
    finan_pedido_recebimento() {
      return this.$store.state.financeiro.lista_finan_pedido_recebimento
    }
  },
  methods: {
    baixar(item) {
      this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação dara baixa nessa conta a receber?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.baixar_finan_contas_receber(item);
          }
        },
      });
    },
    async baixar_finan_contas_receber(value) {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("baixar_finan_contas_receber", {...value, slice: false});
      this.$store.dispatch("MudarPreloader", false);
      this.fireAlert({...this.mensagem_alert});
    },
    async preenxerCampos() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch('show_finan_pedido_recebimento', this.lista_campos.id)
          .finally(() => this.$store.dispatch("MudarPreloader", false))

      this.form = {
        pedido: {
          id: this.finan_pedido_recebimento.id,
          nome: this.finan_pedido_recebimento.nome,
          descricao: this.finan_pedido_recebimento.descricao,
          usuario_id: this.finan_pedido_recebimento.usuario_id,
          usuario: this.finan_pedido_recebimento.usuario,
          data_validade: this.finan_pedido_recebimento.data_validade,
          finan_forma_pagamento_id: this.finan_pedido_recebimento.finan_forma_pagamento_id,
          finan_caixa_id: this.finan_pedido_recebimento.finan_forma_pagamento_id,
          finan_cambio_id: this.finan_pedido_recebimento.finan_cambio_id,
          emissao: this.finan_pedido_recebimento.emissao,
        },
      };
    },
    async listar_requerimentos() {
      this.$store.dispatch('limpar_lista_produtos')
      return Promise.all([
        this.$store.dispatch("listar_finan_caixa"),
        this.$store.dispatch("listar_finan_modo_pagamento"),
        this.$store.dispatch("listar_finan_moeda"),
        this.$store.dispatch("listar_finan_cambio"),
        this.$store.dispatch("listar_usuarios"),
        this.$store.dispatch("listar_produto"),
        this.listar_finan_contas_receber()
      ])
    },
    async listar_finan_contas_receber() {
      let {pedido_id} = this.$route.params
      await this.$store.dispatch("listar_finan_contas_receber", pedido_id);
      await this.$store.dispatch("show_finan_pedido_recebimento", pedido_id);
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>